/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn {
  width:100%; 
}
.quoteBox {
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.card-style1 {
    margin: 10px;
    background-color: #262d27;
    color: #d7c495;
    font-weight: 500;
    font-size: 1.2em;
    text-shadow: 1px 1px 1px black;
    text-align: center;
}
.card-style2 {
    margin: 10px;
    background-color: #684031;
    color: #d7c495;
    font-weight: 500;
    font-size: 1.2em;
    text-shadow: 1px 1px 1px black;
    text-align: center;
}
img{color:red;

    font:bold 40px verdana; 
}
.imageSizeInput {
   
    left:0;
    margin-bottom:25px;
   }
.camWrapper {
    left: 0;
    min-height: 90vh;
       width: 100vw;
}
.code {
    color: #E01A76;
}
.speedSize{
    position:sticky;
    height:10px;
}
.imgcnt{
    max-height: 85vh;
}
.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
.myImgBox {
  /*  width: 100%;*/
 /*   height: 100%;*/
 
}
.marginTop25{
    margin-top:25px;
}
.whiteText{
    color:white;
}
.boxHeight{
    min-height:380px;
}
.center{margin:0 auto;}
.homeCardImage {
    width: inherit;
}
 input[type="range"].spd {
    transform: rotateZ(180deg);
}
/*input[type="range"] {
    transform: rotateZ(180deg);
}*/
.homeImages {
    margin-top:-17px;
    min-height:90vh;
    margin-left:unset;
}
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}
.active{ font-weight:800; color:red;}
.myBtn {
   
}
.sBtn {    width: 100%;
 margin-top: 10px;
}
    .sBtn.btn-outline-primary:hover, .sBtn.btn-outline-primary:focus, .sBtn.btn-outline-primary:active, 
    .sBtn.btn-outline-primary.active, .open > .dropdown-toggle.btn-outline-primary {
        /*  color: #fff;*/
        background-color: #000;
        text-shadow: 1px 1px 1px white;
        /* border-color: #285e8e; */ /*set the color you want here*/
    } 
.QuoteBox {
    /*background-color:#fff1e5;*/
    margin: 15px;
  
    padding-top: 3px;
    padding-bottom: 8px;
    border-top: dotted 1px rgb(207, 207, 207);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 3px;  
     white-space:pre-line;
}
.quoteText {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    padding-left: 35px;
    padding-right: 25px;
    text-shadow: 1px 1px 1px white;
    margin-top: 0px;
    margin-bottom: 1em;
    white-space: pre-wrap;
    display: block;
    /*  text-align: center;*/
    font-size: 1.7em;
}
.aname {
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
    font-weight: bold;
    font-size: x-large;
    padding: 20px;
    margin-bottom: 1px;
    margin-left: 55px;
}
.gwrapper{
    margin-bottom:50px;
}
.reloadBtn{
    margin:auto;
}
.sticky{ position:sticky;}
.lmenu {
  
    height:  90vh ;
    /*  left: -30px;*/
    
    overflow-y: scroll;
    
}
.fleft{float:left;}
.log {
    z-index: 100;
    height: 15vh;
    /*  left: -30px;*/
    top: 0;left: 0;
    overflow-y: scroll;
}
.camsPage {
    position: sticky;
    top: 0;
    /*
 
    padding: 0 !important;
    margin: 0 !important;*/
}
.theImage {
   /* width: 90vw;
    top: 200px;*/
    margin-left: 0px;
    margin-bottom: 30px;
}
.election {
    font-family: Roboto;
  border: 2px solid #ffce00;
    text-shadow: 1px 1px 2px black;
    padding: 3px 8px 3px 8px;
    background-color: #00000f;
    color: #ffce00;
    font-weight: 300;
    font-size: 1.0em;
    text-align: center;
}
/*.searchArea{
    top:0;
    margin-bottom:0;
}*/
@media only screen and (max-width: 767px) {

    .homeImages {
        
        margin-left:0;
    }
    .lmenu {
        height: 10vh;
        /*  left: -30px;*/

        overflow-y: scroll;
    }
}