html, body {
    max-width: 100%;
  /*  overflow-x: hidden*/
}
.myLink {
    /* text-decoration-line:underline;*/
    cursor: pointer;
}
.mysearchButton{
/* padding-right:175px;*/
}
.borderRadius5{
    border-radius:5px;
}
.mainColumns {
    /* margin-left:3px;*/
}
/*.outerBorder {
    box-shadow: 0 3px 6px rgba(0,0,0,0.36), 0 3px 6px rgba(0,0,0,0.53);
    border-radius: 10px;
}*/
.innerBorder {
    border: 0.2rem inset #3f2b2f;
    box-shadow: 0 0 5px #aaa inset;
    border-radius: 10px;
}
    li {
        list-style: none;
    }
    /*div {
    border:1px solid red;
    content:attr(id);
}
span {
    border: 1px solid black;
}*/
.form-select {
    background: transparent;
    color:black;
}
.myDropdown {
  /*  width: 100%;*/
    position: sticky;
    top: 0;
    background: transparent;
}
.hidden {
    display: none;
}
.notHidden {
    display:block;
}
.qdrop {
    position: sticky;
    top: 20px;
    left: 0;
}
.RadioGroupWrap{
    border:1px solid black;
}
.displayarea {
    margin-top: 1.2em;
    padding-bottom: 1em;
}

    .searchArea {
        position:sticky;
         
    float: left;
    margin-left: 0;
       
     
       /* padding-left: 20px;*/
        margin-bottom:10px;
    }

    .logo {
        width: 150px;
    }
  .verseMatch{
       /* border:3px solid blue;*/
      
       font-size:larger;
        
       font-weight:bold;
    }
  .topOfList {
        margin-top: 3em;
    }

    .clickOnIt {
        cursor: pointer;
    }

    #submit {
        position: sticky;
        top: 0px;
        left: 0;
    }

    .textout {
        left: 1px;
        /* padding: 5px;*/
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.2em;
        margin-left: 10px;
        padding-top: 14px;
    }
.columnsHeight  
  {
    height:calc(89vh)
}
.searchBookName {
    position:sticky;
 top: 0px;
    font-weight: 600;
    font-family: Bahnschrift;
    font-size: 1.4em;
    left: 30%;
    padding: 0 15px;
    border: thin solid darkgoldenrod;
    height: calc(6vh);
   /* width: calc(35vw);*/
    /*  left: -30px;*/
  word-break:break-word;
 /*   overflow-y: scroll;*/
  /*  overflow-x: scroll;*/
}

    .chapterandverse {
        font-size: .7em;
        font-weight: 600;
      /*  margin-left: -30px;*/
        opacity: .5;
    }
/*
    .container-fluid {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }

    .container {
        margin: auto;
        padding: 0;
    }*/

   /* header {
        width: 100%;
        margin: 0;
        padding: 0;
    }
*/
.left1 {
  /*  margin-left: -300px;*/
    top: 10px;
  /*  bottom: 0;*/
    height: calc(100vh - 120px);
    /*  left: -30px;*/
    white-space: nowrap;
    overflow-y: scroll;
    overflow-x: hidden;
    /*    vertical-align: top;*/
}

.apppage {
  /*  margin-left: -300px;*/
    /* width:calc(150vw);*/
    padding: 0;
    overflow: hidden;
    height: calc(100vh-105px);
    /*overflow-y: scroll;*/
}

    .top {
        margin-top:80px;
    }

    .wrap {
        /*  min-width:95%;*/
        /* position: relative;
        top: 0px;
        bottom: 0;
        right: 0;*/
        /*   margin-right:-5px;*/
        height: calc(100vh - 105px);
        overflow-y: scroll;
        /*    vertical-align: top;*/
        /*   width: 70%;*/
    }

.middle {
    /* position: relative;*/
    top: 70px;
    bottom: 0;
    word-wrap: break-word;
    /* left: 15%;
        width: 15%;*/
    height: calc(100vh - 105px);
    overflow-y: scroll;
    /*  white-space: nowrap;*/
    /*    vertical-align: top;*/
}
.cardWrap{
    z-index:1000;
    display:block;
    position:absolute;
    top:10px;
    left:50%;
    height:contain;
    width:contain;
    background-color:black;
}
.footer {
    color:#005abb;
    opacity: .6;
    position: fixed;
    bottom: 0px;
  /*  border-top: 1px solid black;*/
    width: 100vw;
    clear: both;
   /* margin: 0 auto;*/
   margin-bottom:0px;
  /* margin-top:5px;*/
    font-size: 0.8em;
    font-family: Roboto;   
 
text-align:center;
    height: 30px;
    text-shadow: 1px 1px 0px black;
     
}
 .searchButton{
     display:none;
 }

@media only screen and (max-width: 1600px)   {

    .theImage {
        width: 90vw;
        top: 200px;
        margin-left: -150px;
        margin-bottom: 30px;
    }
    .left2 {
        height: calc(20vh);
        overflow-y: scroll;
        overflow-x: hidden;
        /*    vertical-align: top;*/
    }
}
    @media only screen and (min-width: 768px) and (max-width: 1080px) {
        .left1 {
            height: calc(15vh);
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .left2 {
            height: calc(20vh);
            overflow-y: scroll;
            overflow-x: hidden;
            /*    vertical-align: top;*/
        }
        .theImage {
            width: 90vw;
            margin-left: 0px;
            /* top: 200px;
 
        margin-bottom: 30px;*/
        }

        .footer {
            opacity: .7;
            position:relative;
            bottom: 0px;
            /*   border-top: 1px solid black;*/
            width: 100%;
            clear: both;
            margin: 0 auto;
            font-size: 1em;
            font-family: Roboto;
            height: 30px;
            text-shadow: 1px 1px 1px black;
            color: darkgoldenrod;
        }

        /*footer {
        opacity: .7;
        color: darkgoldenrod;
        position: fixed;
        bottom: 0px;*/
        /*  border-top: 1px solid black;*/
        /*width: 100%;
        clear: both;
        margin: 0 auto;
        font-size: 1em;
        font-family: Roboto;
        height: 30px;
        text-shadow: 1px 1px 1px black;
     
        background-image: -webkit-gradient(linear, left top, left bottom, from(#684A3A), to(#D7C0A3));
        background-image: -moz-linear-gradient(top, #684A3A, #D7C0A3);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#D7C0A3, endColorstr=#684A3A);*/ /* for IE */
        /*background: -o-linear-gradient(top, #f3e2c7 0%,#684A3A 52%,#D7C0A3 100%);*/ /* Opera 11.10+ */
        /*background: -ms-linear-gradient(top, #f3e2c7 0%,#684A3A 52%,#D7C0A3 100%);*/ /* IE10+ */
        /*  border-top: 2px solid #939393;
        border-bottom: 2px solid rgba( 22, 22, 22,1);*/
        /*}*/

    }

    @media only screen and (max-width: 768px) {
        .footer {
            opacity: .7;
            /* position: fixed;*/
            clear: both;
            position: relative;
            margin-top: 5px;
            /*   bottom: 0px;
        border-top: 1px solid black;*/
            width: 100%;
            font-size: 1em;
            font-family: Roboto;
            height: 30px;
            text-shadow: 1px 1px 1px black;
            color: darkgoldenrod;
            display: flex;
        }

        .theImage {
            width: 95vw;
            margin-left: 0px;
            margin-bottom: 30px;
            /*  top: 200px;
        margin-left: -250px;
        margin-bottom: 30px;*/
        }
        /*  footer {
        display:none;
        
    }*/
        .left1 {
            height: calc(15vh);
            overflow-y: scroll;
            overflow-x: hidden;
            /*    vertical-align: top;*/
        }
        .left2 {
            height: calc(20vh);
            overflow-y: scroll;
            overflow-x: hidden;
            /*    vertical-align: top;*/
        }
        .myBtn {
            width: 85%;
        }

        .resImage {
            width: 100%;
        }

        .columnsHeight {
            height: fit-content;
        }

        .textout {
            left: 1px;
            /* padding: 5px;*/
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 1.2em;
        }

        .displayarea {
            margin-top: 0em;
        }

        .apppage {
            width: calc(100vw);
            padding: 0;
            overflow: hidden;
            height: calc(100vh);
            /*overflow-y: scroll;*/
        }

        .middle {
            /* top: 0px;*/
            /*  bottom: 0;*/
            word-wrap: break-word;
            /* left: 15%;
        width: 15%;*/
            height: calc(80vh);
            overflow-y: scroll;
            /*  white-space: nowrap;*/
            /*    vertical-align: top;*/
        }

        .wrap {
            /*  min-width:95%;*/
            /* position: relative;
        top: 0px;
        bottom: 0;
        right: 0;*/
            /*   margin-right:-5px;*/
            height: calc(75vh );
            overflow-y: scroll;
            /*    vertical-align: top;*/
            /*   width: 70%;*/
        }

        .searchArea {
            /*  z-index: 1000;
    float: left;
    margin-left: 0;*/
            /*  left: 0;
        padding-left: 20px;*/
        }
    }
    /*

.container {
    width: 400px;
    height: 250px;
    background-color: #eeeded;
    border: 1px solid rgba(0, 0, 0, 0.3);
}*/

    .tab-list {
        height: 50px;
        display: flex;
        list-style: none;
        font-size: 18px;
        padding: 0;
        margin: 0;
    }

    .tabs {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #80808013;
        position: relative;
        cursor: pointer;
    }

        .tabs:not(:last-child) {
            border-right: 1px solid rgba(0, 0, 0, 0.3);
        }

    .active-tabs {
        background: #eeeded;
    }

        .active-tabs::before {
            content: "";
            display: block;
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% + 2px);
            height: 4px;
            background: #5893f1;
        }

    .content {
        background-color: #eeeded;
        display: none;
        padding: 10px;
    }

    .active-content {
        display: flex;
    }

